import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useSseEventListener } from '../../../@sprinx/react-after-razzle/sseListener';
import { customerIdState } from '../../../api/appState';
import { useUpdateMainNavigationMatch } from '../../../api/mainNavigation';
import { productTaxonomyTypesQuery } from '../../../api/products/taxonomyTypes';
import { enqueueSnackbarMessage } from '../../../api/snackbars';
import MainNavigationContent from './MainNavigationContent';
export interface MainNavigationProps extends Omit<DrawerProps, 'classes'> {
  classes?: Partial<Record<MainNavigationClassKey, string>>;
  open?: boolean;
}

export type MainNavigationClassKey = 'root' | 'drawerPaper' | 'drawerContainer';

const drawerWidth = 290;

const useStyles = makeStyles((theme: Theme) =>
  createStyles<MainNavigationClassKey, {}>({
    root: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.mainNavigation.background,
      overflowY: 'unset',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        '& .MuiToolbar-regular': {
          minHeight: 'unset',
        },
      },
    },
    drawerContainer: {
      flexGrow: 1,
      overflowY: 'auto',
    },
  }),
);

const MainNavigation = React.forwardRef<any, MainNavigationProps>(({ classes: pClasses, open, ...rest }, ref) => {
  const classes = useStyles({ classes: pClasses });
  useUpdateMainNavigationMatch();
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const resetTaxonomies = useResetRecoilState(productTaxonomyTypesQuery);
  const currentCustomerId = useRecoilValue(customerIdState);

  const handlePricesDownloaded = React.useCallback(
    (customerId: string): void => {
      if (currentCustomerId === customerId) {
        showMessage({ message: `Ceník byl aktualizován ${customerId}`, variant: 'info' });
        resetTaxonomies();
      }
    },
    [currentCustomerId, showMessage, resetTaxonomies],
  );

  // pockat na event, download u cen a reladnout menu
  useSseEventListener('mapo.prices.downloaded', (payload: { customerId: string }): void => {
    handlePricesDownloaded(payload.customerId);
  });

  return (
    <>
      <Hidden mdUp>
        <Drawer
          ref={ref}
          className={classes.root}
          variant='temporary'
          classes={{
            paper: classes.drawerPaper,
          }}
          open={open}
          {...rest}
        >
          <Toolbar />
          <MainNavigationContent className={classes.drawerContainer} />
        </Drawer>
      </Hidden>

      <Hidden smDown initialWidth='md'>
        <Drawer
          ref={ref}
          className={classes.root}
          variant='permanent'
          classes={{
            paper: classes.drawerPaper,
          }}
          {...rest}
        >
          <Toolbar />
          <MainNavigationContent className={classes.drawerContainer} />
        </Drawer>
      </Hidden>
    </>
  );
});

MainNavigation.displayName = 'MainNavigation';

export default MainNavigation;
