type Config = {
  monitorStockAvailability: boolean;
  showStockAvailability: boolean;
};

const allConfigs: Record<string, Config> = {
  mapo: {
    monitorStockAvailability: false,
    showStockAvailability: false,
  },
  pht: {
    monitorStockAvailability: true,
    showStockAvailability: true,
  },
};

const getConfig = (projectName: string) => allConfigs[projectName] || {};

if (!process.env.RAZZLE_PROJECT_NAME) {
  console.error('PROJECT_NAME is not defined');
}

const knownProjects = Object.keys(allConfigs);
const projectName = process.env.RAZZLE_PROJECT_NAME || '';

if (!knownProjects.includes(projectName)) {
  console.error(`PROJECT_NAME "${projectName}" is not known. Known projects are:`, knownProjects.join(', '));
}

export const config = getConfig(projectName);
